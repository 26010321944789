/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, FormEvent } from 'react';
// @ts-ignore
import { Loader, Button, Select } from '@ux/balance-react';
// @ts-ignore
import { IconStar, IconStarSolid, IconSearch } from '@ux/balance-icons';
import { NavLink, useNavigate } from 'react-router-dom-v6';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Lockbox from '../API_Integrations/Lockbox';
import ReportingSummary from '../API_Integrations/ReportingSummary';
import { BatchSummaryMirror, batch_summary_reqBody_lockbox, ReportingSummaryData, reporting_summary_reqBody_endDate, reporting_summary_reqBody_filters, reporting_summary_reqBody_startDate, userState, Exception_Client_CurrentTab, exception_summary_processDate, exception_reportDate, exception_lockbox_code, exception_batch_ID, selected_corp_code, ExceptionSubscribeClient, banker_summary_reqBody_startDate, banker_summary_reqBody_endDate } from '../atom';
import ClientHeader from '../components/ClientHeader';
import CustomModal from '../components/CustomModal';
import ModalsContainer from '../components/ModalsContainer';
import Table from '../components/Table';
import CalendarDateInput from '../components/CalendarDateInput';
import { IReportingSummary_req_body, IReporting_Summary } from '../interfaces/IReportingSummary';
import { ExceptionSummaryRes, exceptionSummaryData, exceptionsCorpCodeData, ExceptionCorpCodeRes, generateExceptionSummaryReport } from '../API_Integrations/exceptionManagement';
import { useTitle } from '../utils/use-title';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import dateformat from 'dateformat';
import { useDifferenceDate} from '../utils/use-date-calculator';
import LockboxdataSummary from '../API_Integrations/LockboxSummary';

function DashboardSummary() {
    useTitle("Lockbox summary");
    const [loading, setLoading] = useState(true);
    const [loadingExceptions, setLoadingExceptions] = useState(false);
    const [currentTab, setCurrentTab] = useRecoilState(Exception_Client_CurrentTab);
    const [processDate, setProcessDate] = useRecoilState(exception_summary_processDate);
    const [corpCodes, setCorpCodes] = useState<ExceptionCorpCodeRes[]>([]);
    const [modalIsOpenViewReport, setModalIsOpenViewReport] = useState(false);
    const [FormattedProcessDate, setFormattedProcessDate] = useState('');
    const [loadingExceptionsSearch, setLoadingExceptionsSearch] = useState(false);
    const [data_exceptions, setData_exceptions] = useState<ExceptionSummaryRes[]>([]);
    const [liveAnnouncementText, setLiveAnnouncementText] = useState("");
    const [selectedReports, setSelectedReports] = useState('dailyDecisionDetail');
    const setException_lockboxCode = useSetRecoilState(exception_lockbox_code);
    const user = useRecoilValue(userState);
    const [lockbox, setLockbox] = useRecoilState(batch_summary_reqBody_lockbox);
    const [reportDate, setReportDate] = useRecoilState(exception_reportDate);
    const setException_batchID = useSetRecoilState(exception_batch_ID);
    const setSelected_corpCode = useSetRecoilState(selected_corp_code);
    const [subscribeException, setSubscribeException] = useRecoilState(ExceptionSubscribeClient);
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const exception_reports_options = [{ label: 'Daily decision detail', value: 'dailyDecisionDetail' }, { label: 'Daily decision summary', value: 'dailyDecisionSummary' }];
    const [loadingSearch, setLoadingSearch] = useState(false);

    //summary
    const [data_summary, setData_summary] = useRecoilState(ReportingSummaryData);
    const startDate = useRecoilValue(reporting_summary_reqBody_startDate);
    const endDate = useRecoilValue(reporting_summary_reqBody_endDate);
    const filters = useRecoilValue(reporting_summary_reqBody_filters);
    const setBatchMirror = useSetRecoilState(BatchSummaryMirror);
    const reqBody: IReportingSummary_req_body = {
        "sorts": "-isFavoriteLockbox",
        "filters": filters,
        "startDate": startDate,
        "endDate": endDate
    }
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);

    useFocusOnElemnt(initialTextRef);

    const navigate = useNavigate();



    useEffect(() => {
        getReportingSummary();
    }, []);

    useEffect(() => {
    }, [data_summary]);

    useEffect(() => {
    }, [lockbox]);

    //reporting summary API
    const getReportingSummary = async () => {
        setLoading(true);
        
        let result = await ReportingSummary.details(reqBody);
        if (result !== undefined) {
            setData_summary(result);
            const hasExceptions = result.some((lockbox: IReporting_Summary) => lockbox.lockboxType === "Retail" && lockbox.isOnlineDecision);
                
                if(hasExceptions) {
                    
                    if (user.isAdmin || user.canManageExceptions) {
                        setFormattedProcessDate(dateformat(new Date(), "mm/dd/yyyy"));
                        setProcessDate(new Date());
                        const date = dateformat(new Date(), "yyyy-mm-dd");
                        
                        getExceptionData(date);
                        setSubscribeException({ type: "Retail", subscribed: true });
                    }
                }
            setLoading(false);
        } else {
            console.log('item not found')
        }
    }

    const getExceptionData = async (date: string) => {
        setLoadingExceptions(true);
        let result = await exceptionSummaryData(date);
        let corpCodesResult = await exceptionsCorpCodeData(date);
        if (result !== undefined) {
            setData_exceptions(result);
            setLoading(false);
        } else {
            console.log('items not found');
        }
        if (corpCodesResult !== undefined) {
            setCorpCodes(corpCodesResult);
        }
        setLoadingExceptions(false);
    }

    const addFavorite = async (ID: number) => {
        await Lockbox.addFavorite(ID);
        getReportingSummary();
    }
    const deleteFavorite = async (ID: number) => {
        await Lockbox.deleteFavorite(ID);
        getReportingSummary();
    }
    const handleAddFavorite = (code: number) => {
        const favoritelockboxCode = code;
        addFavorite(favoritelockboxCode);

    }
    const handleDeleteFavorite = (code: number) => {
        const unfavoritelockboxCode = code;
        deleteFavorite(unfavoritelockboxCode);
    }

    //View Report as PDF
    const handleViewReport = () => {
        LockboxdataSummary.generateReport({ startDate: dateformat(startDateCalendar, "yyyy-mm-dd"), endDate: dateformat(endDateCalendar, "yyyy-mm-dd"), fileType: 'Pdf' });
        setModalIsOpenViewReport(true);
    }

    const handleLockboxSelection = (row: any) => {
        setLockbox(row.lockboxCode);
        setBatchMirror({
            lockboxCode: row.lockboxCode,
            lockboxName: row.lockboxName || '',
            siteName: row.siteName || '',
            totalDeposits: row.totalDepositsFormatted,
            totalBatches: row.totalBatchesFormatted,
            totalTransactions: row.totalTransactionsFormatted,
            status: row.status
        });
    };
    //
    const HandleOnChangeProcessDate = (date: any) => {
        setProcessDate(date);
    }
    const HandleOnChangeReportDate = (date: any) => {
        setReportDate(date);
    }

    const HandleExceptionSearch = async () => {
        const date = dateformat(processDate, "yyyy-mm-dd");
        setLoadingExceptionsSearch(true);
        try {
            setLiveAnnouncementText("Loading, please wait.");
            const result = await exceptionSummaryData(date);
            let corpCodesResult = await exceptionsCorpCodeData(date);
            if (corpCodesResult !== undefined) {
                setCorpCodes(corpCodesResult);
            }
            if (result !== undefined) {
                setData_exceptions(result);
                setFormattedProcessDate(dateformat(processDate, "mm/dd/yyyy"));
                if (result && result.length > 1) {
                    setLiveAnnouncementText(`${result.length} results found.`);
                } else if (result && result.length === 1) {
                    setLiveAnnouncementText(`${result.length} result found.`);
                } else {
                    setLiveAnnouncementText("You don’t have any exceptions for this date. Please choose another process date.");
                }

            } else {
                console.log('items not found');

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingExceptionsSearch(false);
        }
    }

    useEffect(() => {
        const selectedTab = document.querySelector('[aria-selected="true"]') as HTMLElement | null;
        if (selectedTab) {
            selectedTab.focus();
        }
    }, [currentTab]);

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();

                const targetedElement = document.activeElement as HTMLElement;
                const searchFormException = document.getElementById('exception-summary-form') as HTMLFormElement | null;

                if (searchFormException && searchFormException.checkValidity() && (targetedElement.classList.contains('exception-summary-search-button') || targetedElement.tagName === 'BODY')) {
                    HandleExceptionSearch();
                }
                if (targetedElement.tagName === 'BUTTON' && (!targetedElement.classList.contains('exception-summary-search-button'))) {
                    targetedElement.click();
                }
                if (targetedElement.tagName === 'A' && (!targetedElement.classList.contains('exception-summary-search-button'))) {
                    targetedElement.click();
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, [HandleExceptionSearch]);

    const handleExceptionBoxSelection = (row: any) => {
        setException_lockboxCode(row.lockboxCode);
        navigate(`/exception-batch-summary/${row.lockboxCode}`);
    };
    const handleOnChangeSelectReports = (event: Event) => setSelectedReports((event.target as HTMLInputElement).value);

    const handleGoToReports = () => {
        if (selectedReports === 'dailyDecisionSummary') {
            navigate(`/exception-daily-summary/`);
        } else if (selectedReports === 'dailyDecisionDetail') {
            navigate(`/exception-daily-decision/`);
        }
    }

    const handleCorpCodeSelection = (row: any) => {
        setException_batchID(0);
        setSelected_corpCode(row.corpCode);
        setException_lockboxCode(row.lockboxCode);
        navigate(`/exception-batch-detail/${row.lockboxCode}`);
    };

    const handleCSV = () => {
        generateExceptionSummaryReport({ processingDate: dateformat(processDate, "yyyy-mm-dd"), fileType: 'Csv' })
        setModalIsOpenViewReport(true);
    }

    const handleDownloadReports = () => {
        handleCSV();
        setModalIsOpenDownload(true);
    }

    const [showValidationDateDiff, setShowValidationDateDiff] = useState(false);

    const [startDateCalendar, setstartDateCalendar] = useRecoilState(banker_summary_reqBody_startDate);
    const [endDateCalendar, setendDateCalendar] = useRecoilState(banker_summary_reqBody_endDate);



    //start date
    const HandleOnChangeStartDate = (date: any) => {
        setstartDateCalendar(date);
    }

    //end date
    const HandleOnChangeEndDate = (date: any) => {
        setendDateCalendar(date);
    }

    const HandleLockboxSearch = async () => {
        setLoadingSearch(true);

        const isNotValid = useDifferenceDate(startDateCalendar, endDateCalendar);
        if (isNotValid) {
            setShowValidationDateDiff(true);
            setLoadingSearch(false);
            return;
        }

        const updatedReqBody: IReportingSummary_req_body = {
            sorts: "-isFavoriteLockbox",
            filters: filters,
            startDate: dateformat(startDateCalendar, "yyyy-mm-dd"),
            endDate: dateformat(endDateCalendar, "yyyy-mm-dd")
        };
        let result = await ReportingSummary.details(updatedReqBody);
        if (result !== undefined) {
            setData_summary(result);
        } else {
            console.log('items not found');
        }
        setLoadingSearch(false);
    };


    const ReportingSummaryTab = () => {
        return (
            <div className='tabs-content top-gap-20'>
                
                <form className='lockbox-search-control' style={{marginBottom: "-2.3em"}} onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); HandleLockboxSearch(); }} id='reporting-summary-form'>
                            <CalendarDateInput startDateWithEndPopover={true}  label='Enter deposit start date' selected={startDateCalendar} onChange={HandleOnChangeStartDate} name='Enter deposit start date' id='input-start-date' />
                            <div className="left-24-gap-filters" style={{ display: 'flex' }}>
                                <CalendarDateInput label='Enter deposit end date' selected={endDateCalendar} onChange={HandleOnChangeEndDate} name='Enter deposit end date' id='input-end-date' showPopover={true} popoverText='View up to 90 days of information at a time.' />
                            </div>
                            <div className='lockbox-search-button reporting-summary-button'>
                                <Button
                                    classes="reporting-summary-search-button"
                                    size="medium"
                                    text="Search"
                                    type="submit"
                                    isLoading={loadingSearch}
                                />
                            </div>
                        </form>
                    
                
                <CustomModal
                    okType
                    ModalText='Please enter a date range up to 90 days.'
                    ModalTitle='Incorrect date range'
                    ModalIsOpen={showValidationDateDiff}
                    CloseModal={() => {
                        setShowValidationDateDiff(false);
                    }}
                />
            <Loader isLoading={loading} />
            {(data_summary.length > 0) ?
                <>
                    <ModalsContainer>
                        <CustomModal ButtonTitle='Download report as PDF' ModalIsOpen={modalIsOpenViewReport} OpenModal={handleViewReport} CloseModal={() => { setModalIsOpenViewReport(false) }} ModalText='Your PDF has been requested and will be available in Reports.' ModalTitle='Request submitted' />
                    </ModalsContainer>
                    <div className='reprting-summary-table'>
                        <Table
                            ariaDescribedBy={null}
                            caption='Reorting summary'
                            classes=""
                            columnData={
                                [
                                    {
                                        headerId: 'isFavoriteLockbox',
                                        headerLabel: 'Favorites',
                                        type: 'component',
                                        rowCellTableRowComponentsArrayIndex: 1
                                    },
                                    {
                                        rowCellTableRowComponentsArrayIndex: 0,
                                        headerId: 'lockboxCode',
                                        type: 'component',
                                        headerLabel: 'Box ID'
                                    },
                                    {
                                        headerId: 'siteName',
                                        headerLabel: 'Site name '
                                    },
                                    {
                                        headerId: 'lockboxName',
                                        headerLabel: 'Box name'
                                    },
                                    {
                                        headerId: 'totalDepositsFormatted',
                                        headerLabel: 'Deposit'
                                    },

                                    {
                                        headerId: 'totalBatches',
                                        headerLabel: 'Batches'
                                    },
                                    {
                                        headerId: 'totalTransactionsFormatted',
                                        headerLabel: 'Transactions'
                                    },
                                    {
                                        headerId: 'status',
                                        headerLabel: 'Status'
                                    }
                                ]
                            }
                            data={data_summary}
                            tableRowComponents={[
                                (props: any) => (
                                    <NavLink
                                        to={`/batch-summary/${props.row.original.lockboxCode}`}
                                        onClick={() => handleLockboxSelection(props.row.original)}>
                                        {props.row.original.lockboxCode}
                                    </NavLink>

                                ),
                                (props: any) => (
                                    <div>
                                        {(props.row.original.isFavoriteLockbox) ?
                                            <NavLink className='unfavorite-links' to={`#`} onClick={() => { handleDeleteFavorite(props.row.original.lockboxCode) }}><IconStarSolid style={{ color: '#417514' }} width="24" height="24" /></NavLink>
                                            :
                                            <NavLink className='favorite-links' to={`#`} onClick={() => { handleAddFavorite(props.row.original.lockboxCode) }}><IconStar style={{ color: '#417514' }} width="24" height="24" /></NavLink>
                                        }

                                    </div>

                                )
                            ]}
                        />
                    </div>
                </>
                :
                <p className='zero-state top-gap-32'>No result found.</p>
            }
        </div>)
    }

    return (
        <main>
            <ClientHeader showClientName={true} showBreadcrumbs={false} />
            {(subscribeException.type === 'Retail' && subscribeException.subscribed) && (user.isAdmin || user.canManageExceptions) ?
                <div className='tabs-container'>
                    <div role='tablist' className='tabs-header'>
                        <button
                            role='tab'
                            aria-selected={currentTab === "REPORTINGSUMMARY"}
                            aria-controls="REPORTINGSUMMARY"
                            onClick={() => { setCurrentTab('REPORTINGSUMMARY') }}
                            className={currentTab === 'REPORTINGSUMMARY' ? 'custom-tabs active-tabs' : 'custom-tabs'}>
                            {user.restrictRegularTransactions
                                ? 'Reporting summary 0 lockboxes'
                                : `Reporting summary (${data_summary.length} ${data_summary.length === 1 ? 'lockbox' : 'lockboxes'})`}
                        </button>
                        <button
                            role='tab'
                            aria-selected={currentTab === "EXCEPTIONMANAGEMENT"}
                            aria-controls="EXCEPTIONMANAGEMENT"
                            onClick={() => { setCurrentTab('EXCEPTIONMANAGEMENT') }}
                            className={currentTab === 'EXCEPTIONMANAGEMENT' ? 'custom-tabs active-tabs' : 'custom-tabs'}>
                            Exception management ({data_exceptions.length})
                        </button>
                        
                    </div>
                    <div role='tabpanel' className='tabs-content top-gap-40'>
                        {currentTab === 'REPORTINGSUMMARY' ? (
                            <>
                                {!user.restrictRegularTransactions ? <ReportingSummaryTab /> : <div>You don't have access to view the reporting summary for any Lockboxes.</div>}
                            </>
                        )
                            : currentTab === 'EXCEPTIONMANAGEMENT' ? (
                                <div>
                                    {!loadingExceptions ?
                                        <div>
                                            <div className='exception-summary-heading'>
                                                <h4 ref={initialTextRef} tabIndex={-1} className="text-style-heading-4 heading-focus-text">Exception summary for {FormattedProcessDate}</h4>
                                            </div>
                                            <form className='exception-search-control' onSubmit={(e: FormEvent<HTMLFormElement>) => { e.preventDefault(); HandleExceptionSearch(); }} id='exception-summary-form'>
                                                <CalendarDateInput label='Select process date' selected={processDate} onChange={HandleOnChangeProcessDate} name='Select process date' id='input-process-date' />
                                                <div className=''>
                                                    <Button
                                                        classes="exception-summary-search-button"
                                                        size="medium"
                                                        text="Search"
                                                        type="submit"
                                                        customIcon={IconSearch}
                                                        iconPosition='left'
                                                        isLoading={loadingExceptionsSearch}
                                                    />
                                                </div>
                                            </form>
                                            <span aria-live='polite' className='announcement-text'>{liveAnnouncementText}</span>
                                            {!loadingExceptions && data_exceptions.length > 0 ?
                                                <div className='lockbox-summary-table'>
                                                    <ModalsContainer>
                                                        <CustomModal
                                                            downloadIconButton={true}
                                                            showClose={true}
                                                            ButtonTitle='Download report as CSV'
                                                            ModalIsOpen={modalIsOpenDownload}
                                                            OpenModal={handleDownloadReports}
                                                            CloseModal={() => { setModalIsOpenDownload(false) }}
                                                            ModalText='Your report has been requested and will be available in Reports.'
                                                            ModalTitle='Request submitted'
                                                        />
                                                    </ModalsContainer>
                                                    <Table
                                                        ariaDescribedBy={null}
                                                        caption={`Exception Summary, for ${FormattedProcessDate}`}
                                                        classes="vertically-ruled-table"
                                                        columnData={
                                                            [
                                                                {
                                                                    rowCellTableRowComponentsArrayIndex: 0,
                                                                    headerId: 'lockboxCode',
                                                                    type: 'component',
                                                                    headerLabel: 'Box ID'
                                                                },
                                                                {
                                                                    headerId: 'lockboxName',
                                                                    headerLabel: 'Box name'
                                                                },
                                                                {
                                                                    headerId: 'totalVolumeFormatted',
                                                                    headerLabel: 'Total volume'
                                                                },
                                                                {
                                                                    headerId: 'totalAmountFormatted',
                                                                    headerLabel: 'Total amount'
                                                                },
                                                                {
                                                                    headerId: 'completedVolumeFormatted',
                                                                    headerLabel: 'Completed volume'
                                                                },
                                                                {
                                                                    headerId: 'completedAmountFormatted',
                                                                    headerLabel: 'Completed amount'
                                                                },
                                                                {
                                                                    headerId: 'returnedVolumeFormatted',
                                                                    headerLabel: 'Returns volume'
                                                                },
                                                                {
                                                                    headerId: 'returnedAmountFormatted',
                                                                    headerLabel: 'Returns amount',
                                                                },
                                                                {
                                                                    headerId: 'escalatedVolumeFormatted',
                                                                    headerLabel: 'Escalated volume',
                                                                },
                                                                {
                                                                    headerId: 'escalatedAmountFormatted',
                                                                    headerLabel: 'Escalated amount'
                                                                },
                                                                {
                                                                    headerId: 'rejectedVolumeFormatted',
                                                                    headerLabel: 'Rejected volume',
                                                                },
                                                                {
                                                                    headerId: 'rejectedAmountFormatted',
                                                                    headerLabel: 'Rejected amount'
                                                                },
                                                            ]
                                                        }
                                                        data={data_exceptions}
                                                        tableRowComponents={[
                                                            (props: any) => (
                                                                <Button
                                                                    variant="text-primary"
                                                                    clickMethod={() => handleExceptionBoxSelection(props.row.original)}
                                                                    text={props.row.original.lockboxCode}
                                                                    classes="handle-lockbox-selection underlined-links"
                                                                    dataTestId={props.row.original.lockboxCode}
                                                                />
                                                                // <p>
                                                                //     <a onClick={() => handleExceptionBoxSelection(props.row.original)} className="table-row-links" href={`/exception-batch-summary/${props.row.original.lockboxCode}`}>{props.row.original.lockboxCode}</a>
                                                                // </p>
                                                            ),
                                                        ]}
                                                    />
                                                    {(corpCodes && corpCodes.length > 0) && <div>
                                                        <div className='light-divider'></div>
                                                        <p className='corp-code-title'>Corp Codes</p>
                                                        <div>
                                                            <Table
                                                                ariaDescribedBy={null}
                                                                caption={`Corp codes for ${FormattedProcessDate}`}
                                                                classes=""
                                                                columnData={
                                                                    [
                                                                        {
                                                                            headerId: 'lockboxCode',
                                                                            headerLabel: 'Box ID'
                                                                        },
                                                                        {
                                                                            rowCellTableRowComponentsArrayIndex: 0,
                                                                            headerId: 'code',
                                                                            type: 'component',
                                                                            headerLabel: 'Corp code'
                                                                        },
                                                                        {
                                                                            headerId: 'exceptions',
                                                                            headerLabel: 'Exceptions'
                                                                        },
                                                                        {
                                                                            headerId: 'exceptionsTotalFormatted',
                                                                            headerLabel: 'Exception total'
                                                                        }
                                                                    ]
                                                                }
                                                                data={corpCodes}
                                                                tableRowComponents={[
                                                                    (props: any) => (
                                                                        <Button
                                                                            variant="text-primary"
                                                                            clickMethod={() => handleCorpCodeSelection(props.row.original)}
                                                                            text={`${props.row.original.corpCode} - ${props.row.original.corpCodeName}`}
                                                                            classes="handle-lockbox-selection underlined-links"
                                                                            dataTestId={props.row.original.corpCode}
                                                                        />
                                                                        // <p>
                                                                        //     <a onClick={() => handleCorpCodeSelection(props.row.original)} className="table-row-links" href={`/exception-transaction-detail/${props.row.original.corpCode}`}>{props.row.original.corpCode} - {props.row.original.corpCodeName}</a>
                                                                        // </p>
                                                                    ),
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className='light-divider'></div>
                                                    <p className='exception-reports-title'>Exception reports</p>
                                                    <div className='exception-report-control '>
                                                        <Select
                                                            label="Report type"
                                                            name="Report type"
                                                            size='small'
                                                            classes='width-242'
                                                            options={exception_reports_options}
                                                            handleOnChange={handleOnChangeSelectReports}
                                                            value={selectedReports}
                                                        />
                                                        <div className="left-24-gap-filters width-226 top-gap-8">
                                                            <CalendarDateInput label='Enter report date' selected={reportDate} onChange={HandleOnChangeReportDate} name='Enter report date' id='input-report-date' />
                                                        </div>
                                                        <div className='left-24-gap-filters top-gap-32 '>
                                                            <Button
                                                                classes="exception-summary-report-button"
                                                                size="medium"
                                                                text="Go to report"
                                                                variant="secondary"
                                                                clickMethod={() => handleGoToReports()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : !loadingExceptions && data_exceptions.length === 0 ?
                                                    <p className='zero-state'>You don’t have any exceptions for this date. Please choose another process date.</p>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        :
                                        <Loader />
                                    }
                                </div>
                            )
                                :
                                <></>
                        }

                    </div>
                </div>
                :
                <ReportingSummaryTab />
            }
        </main>);
}

export default DashboardSummary;
