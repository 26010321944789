import requests from "./apiRequest";
import { ILockboxesTransactionSearch_req_body, IDetailKeyRes, ILockboxesTransactionSearch } from "../interfaces/ILockboxesTransactionsSearch";
import axios from "axios";

export interface LockboxTransactionsReportRequest {
    startDate: string
    endDate: string
    filters?: string
    detailFilters?: string
    corpCode?: string
    sorts?: string
    includeChecks: boolean
    includeDocuments: boolean
    fileType: "csv" | "pdf" | "zip"
    selectedTransactions: number[]
    ColumnOrder?: string[]
    allLockboxesSelected?: boolean
}

const LockboxesTransactions = {
    listTransactions: async (lockboxId: string, body: ILockboxesTransactionSearch_req_body) => {
        try {
            let result: ILockboxesTransactionSearch[] = await requests.getParams(`lockboxes/${lockboxId}/transactions`, body);
            return result;
        } catch (error) {
            console.log('error from lockbox api integration: ', error);
        }
    },

    getAdditionalDetailKeys: async (lockboxCode: string): Promise<IDetailKeyRes[]> => {
            let result = await requests.getParams(`/detail-keys`, {lockboxCode: lockboxCode} );
            return result;
    },

    createReport: async (lockboxId: string, body: LockboxTransactionsReportRequest) => {
        await axios.post(`/lockboxes/${lockboxId}/transactions/report`, body);
    },

    createCorpCodeReport: async (lockboxId: string, body: LockboxTransactionsReportRequest) => {
        await axios.post(`/lockboxes/${lockboxId}/transactions/corpcodereport`, body);
    }
}
export default LockboxesTransactions;
